export type {};

declare const self: ServiceWorkerGlobalScope;
const refresh = 1;

const deleteCache = async () => {
    const app = await self.caches.open("app");
    await app.keys().then((names)  => names.map(x => app.delete(x))).then(x => Promise.all(x));

    const fonts = await  self.caches.open("fonts");
    await fonts.keys().then((names)  => names.map(x => fonts.delete(x))).then(x => Promise.all(x));

    const sentry = await self.caches.open("sentry");
    await sentry.keys().then((names)  => names.map(x => sentry.delete(x))).then(x => Promise.all(x));
}

const assetPath = "/spa/asset-manifest.client.json"

self.addEventListener("install" , (event) => {
    event.waitUntil((async() =>{
        await deleteCache()
        const app = await self.caches.open("app");
        const manifest = await fetch(assetPath);
        if (manifest.status === 200) {
            await app.put(assetPath, manifest.clone());
        }
        return
    })());
});

self.addEventListener("activate", (event) => {
    event.waitUntil((async() => {

        const manifest = await fetch(assetPath);
        if (manifest.status === 200) {
            const oldManifest = await (await self.caches.open("app")).match(assetPath);
            const cacheresp = manifest.clone();
            if (!oldManifest || (await oldManifest.text()) !== (await manifest.text()) )
            {
                await deleteCache();
                const app = await self.caches.open("app");
                await app.put(assetPath, cacheresp);
            }
        }
        await self.clients.claim();

    })());
})



self.addEventListener("fetch", (event) => {
    const url = new URL(event.request.url);

    const getCache = async (name: string) => {
        const cache = await self.caches.open(name);
        const matched= await cache.match(url);
        if (matched) {
            return matched;
        }

        const response = await fetch(url);
        if (response.status === 200) {
            if (url.pathname.match(/\.js$/)) {
                try {
                    new Function(await response.clone().text());
                }
                catch (e) {
                    return response;
                }
            }
            await cache.put(url, response.clone());
        }
        return response;
    }
    if (url.pathname.includes("/spa/")) {
        event.respondWith((async() => {
            return getCache("app");
        })())
        return;
    }

    if (url.host.includes("fonts.googleapis.com")
        || url.host.includes("fonts.gstatic.com")) {

        event.respondWith((async() => {
            return getCache("fonts");
        })());
        return;
    }

    if (url.pathname === "/sentry.js") {
        event.respondWith((async() => {
            return getCache("sentry");
        })());
        return;
    }
})